.globalTexfieldStyle {
    background-color: #ffffff !important;
    border-radius: 5px !important;
}

.globalTexfieldStyle :global(.MuiInputBase-input) {
    padding: 10px !important;
}

.globalTexfieldStyle :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}