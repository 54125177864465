.gblHeaderDialogItem {
    position: sticky;
    top: 0px;
    background-color: #273348;
    padding: 20px 0px 0px !important;
    z-index: 10;
}

.dialogClassName :global(.MuiPaper-root) {
    margin: 0px !important;
    width: calc(100% - 30px) !important;
}