.navigationMainContainer {
    background-color: #273348;
    color: white;
    position: relative;
}


.navigationWrapper {
    height: 100% !important;
    // min-height: 100% !important;
    // width: 252px !important;
    // min-width: 100% !important;
    // padding: 20px 15px !important;
}

.navigationList {
    padding: 0 0 8px 0 !important;
}

.sideNavigationList {
    margin: 10px 0px !important;
    border-radius: 5px !important;
    display: flex !important;
    align-items: center !important;
    padding: 15px 15px !important;
    cursor: pointer;
}

.sideNavigationNameIcon {
    max-width: 40px !important;
    display: flex;
    align-items: center;
}

.sideNavigationName {
    max-width: calc(100% - 50px) !important;
    width: calc(100% - 50px) !important;
}

.sideNavigationNameIcon div {
    display: flex !important;
    align-items: center;
    width: 100% !important;
}

.sideNavigationMainItem {
    margin-top: 20px !important;
}

.drawerOpenIcon {
    position: fixed !important;
    transform: translateX(70px) translateY(0px) rotate(180deg);
    height: auto;
    left: 0px !important;
}

.drawerCloseIcon {
    width: 20px;
    position: fixed !important;
    transform: translateX(230px) translateY(0px);
}

.ellipsisStyles {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
}

.appLogo {
    width: 220px;
    height: auto;
    object-fit: fill;
    vertical-align: middle;
}

.sideNavListAppsContainer {
    height: calc(100% - 50px) !important;
}

@media screen and (max-width: 1201px) and (min-width: 0px) {
    .drawerCloseIcon {
        display: none !important;
    }

    .drawerOpenIcon {
        display: none !important;
    }


}

@media screen and (max-width: 335px) and (min-width: 0px) {
    .appLogo {
        width: 160px;
        height: auto;
        object-fit: fill;
        vertical-align: middle;
    }
}