.profileWrapperItem {
    background-color: #273348 !important;
    padding: 25px !important;
    border-radius: 5px !important;
}

.profileImageContainer {
    justify-content: space-between;
    gap: 20px !important;
    align-items: center;
}

.profileImageItem {
    width: 100px !important;
    max-width: 100px !important;
}

.profileContentItem {
    width: calc(100% - 120px) !important;
}

.updateProfileBtn {
    background-color: #105EA7 !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    text-transform: none !important;
    box-shadow: none !important;
}

.gblProfileTextfieldItem {
    width: 270px !important;
    min-width: 270px !important;
    max-width: 270px !important;
}

@media screen and (max-width: 600px) and (min-width: 0px) {
    .profileWrapperItem {
        padding: 20px 10px !important;
    }

    .gblProfileTextfieldItem {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }
}