.registrationBannerItem {
    background-image: url("../../../Assets/Register/register-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 50px;
}


.registerAcrLogo {
    width: 320px;
    height: auto;
}

.iframeStyles {
    width: 600px;
    height: 400px;
    margin: 0 auto !important;
}

.regBannerLeftItem {
    padding: 35px !important;
}

.regFormMainItem {
    margin: 50px auto !important;
    max-width: 2000px !important;
}

.regFormHeadItem {
    margin: 0px 0px 10px !important;
}

.regDivider {
    width: 100%;
    margin: 0px 0px 15px !important;
}

.regFieldsMainItem {
    max-width: 350px !important;
    width: 350px !important;
}

.regFieldHeadItem {
    margin: 0px 0px 5px !important;
}

.regAutocommplate {
    background-color: #F7FAFC !important;
}

.registerBtn {
    background-color: #273348 !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    width: 350px !important;
}

.registerHeadWrapper {
    max-width: 2000px !important;
}




@media screen and (max-width: 1880px) and (min-width: 0px) {
    .iframeStyles {
        width: 100%;
        height: 500px;
        margin: 0 auto !important;
    }
}




@media screen and (max-width: 1200px) and (min-width: 0px) {
    .registrationFormBelowTab {
        background-color: #1A2537;
        position: relative;
    }

    .signupElipsis img {
        width: 550px;
        height: auto;
    }

    .regFormMainItem {
        margin: 10px auto 50px !important;
        max-width: 2000px !important;
    }

    .signupMacLogoItem {
        margin: 50px auto 10px !important;
        text-align: center;
    }

    .signupMacLogo {
        width: 300px;
        height: auto;
    }

    .siginupRelative {
        position: relative;
    }

    .signupElipsis {
        position: absolute;
        top: 0;
        right: 0;
    }

    .registerBtn {
        background-color: #273348 !important;
        border-radius: 5px !important;
        color: #ffffff !important;
        font-size: 15px !important;
        font-weight: 500 !important;
        text-transform: none !important;
        width: 350px !important;
        border: 1px solid rgb(145, 145, 145) !important;
    }

    .regCheckbox {
        color: #ffffff !important;
    }

    .regCheckbox :global(.MuiButtonBase-root) {
        color: #ffffff !important;
    }

}

@media screen and (max-width: 900px) and (min-width: 600px) {
    .regFieldsMainItem {
        max-width: 46% !important;
        width: 46% !important;
    }
}


@media screen and (max-width: 600px) and (min-width: 0px) {
    .registerBtn {
        width: 100% !important;
    }

    .gRecaptcha {
        transform: scale(0.8) !important;
        transform-origin: 0 0 !important;
        -webkit-transform-origin: 0 0 !important;
    }

    .signupElipsis img {
        width: 250px;
        height: auto;
    }

    .signupMacLogo {
        width: 300px;
        height: auto;
    }

    .regFieldsMainItem {
        max-width: 100% !important;
        width: 100% !important;
    }

}

@media screen and (max-width: 350px) and (min-width: 0px) {
    .signupMacLogo {
        width: 100%;
        height: auto;
    }
}