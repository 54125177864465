.featureCardTextItem span {
    color: #fff;
}

.featureCardTextItem {
    text-align: center;
    margin: 10px auto !important;
}

.featureCardImgItem {
    // background-color: #ffffff !important;
    // border-radius: 10px !important;
}

.featureImages {
    width: 150px;
    height: 140px;
    object-fit: fill;
    border-radius: 20px;
}

// RESPONSIVE

@media screen and (max-width: 1440px) and (min-width: 0px) {

    .featureImages {
        width: 130px;
        height: 120px;
        object-fit: fill;
        border-radius: 20px;
    }
}