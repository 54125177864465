.signinTextfield {
    background-color: rgba(231, 235, 238, 0.2) !important;
}

.signinTextfield input {
    border-radius: 10px !important;
    padding: 12px 15px !important;
}


.signinTextfield :global(.MuiOutlinedInput-notchedOutline) {
    border-radius: 10px !important;
}

.signinTextfield :global(.MuiInputBase-root) {
    padding-right: 0px !important;
}

.signinBgWrapper {
    background-color: rgba(26, 37, 55, 1);
    padding: 80px 0px !important;
    position: relative;
}

.signinMainContainer {
    justify-content: space-between;
    position: relative;
}

.signinFormItem {
    max-width: 500px !important;
    margin: 0 auto !important;
}

.signInButton {
    background-color: rgba(39, 51, 72, 1) !important;
    color: #ffffff !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    border-radius: 8px !important;
    text-transform: none !important;
    padding: 10px 15px !important;
    box-shadow: none !important;
}

.signinBgSubWrapper {
    margin: 0 auto !important;
}

.signinIntroText {
    width: 600px !important;
    margin: 0px auto !important;
    text-align: start !important;
}

.signinIntroText span {
    color: rgba(247, 250, 252, 1) !important;
    font-weight: 600 !important;
    font-size: 24px !important;
}

.signinIntroSubText {
    width: 600px !important;
    margin: 10px auto !important;
    text-align: start !important;
}

.signinIntroSubText span {
    color: rgba(207, 217, 224, 1) !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.signinIframe {
    border: 1px solid white !important;
    border-radius: 10px !important;
}

.registerLink {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #718096 !important;
}

.registerLinkText {
    color: #1C4532 !important;
    font-weight: 500 !important;
}

.gRecaptcha {
    transform: scale(1) !important;
    -webkit-transform: scale(1.2) !important;
    transform-origin: 0 0 !important;
    -webkit-transform-origin: 0 0 !important;
}

.signinElipsis {
    position: absolute;
    top: 0;
    right: 0;
}

.signinElipsis img {
    width: 520px;
    height: 550px;
}

.signinIframeItem iframe :global(.player) {
    border-radius: 30px !important;
    border: 1px solid white !important;
}

.signinIframeItem {
    height: 400px;
    width: 600px !important;
    z-index: 1;
    margin: 190px auto 10px !important;
}

@media screen and (max-width: 1200px) and (min-width: 0px) {

    .siginMacLogoBelowTab {
        width: 400px;
        height: auto;
        object-fit: fill;
    }

    .signinBgWrapper {
        display: none;
    }

    .signinBelowTabStyles {
        background-color: rgba(26, 37, 55, 1);
        padding: 0px 0px 10px;
    }

    .signinLeftSection {
        padding: 50px 0px;
    }

    .signinCheckboxLabel {
        color: #ffffff !important;
    }

    .signinCheckboxLabel :global(.MuiButtonBase-root) {
        color: #ffffff !important;
    }

    .signinForgetPwtext {
        color: #ffffff !important;
    }

    .signInButton {
        background-color: #ffffff !important;
        color: #273348 !important;
    }

    .registerLink {
        color: #C4C4C4 !important;
    }

    .registerLinkText {
        color: #ffffff !important;
    }

    .signinTextfield {
        background-color: transparent !important;
    }

    .signinTextfield :global(.MuiInputBase-root) {
        background-color: #ffffff;
        border-radius: 10px !important;
    }
}

@media screen and (max-width: 768px) and (min-width: 0px) {
    .signinLeftSection {
        padding: 50px 0px;
    }

    .signinFormItem {
        max-width: 85% !important;
        width: 85% !important;
        margin: 0 auto !important;
    }

    .signinElipsis img {
        width: 300px;
        height: 320px;
    }

    .gRecaptcha {
        transform: scale(1) !important;
        transform-origin: 0 0 !important;
        -webkit-transform-origin: 0 0 !important;
    }
}

@media screen and (max-width: 600px) and (min-width: 0px) {
    .siginMacLogoBelowTab {
        width: 80%;
        height: auto;
        object-fit: fill;
    }
}

@media screen and (max-width: 400px) and (min-width: 0px) {
    .signinFormItem {
        max-width: 90% !important;
        width: 90% !important;
        margin: 0 auto !important;
    }
}

@media screen and (max-width: 350px) and (min-width: 0px) {
    .gRecaptcha {
        transform: scale(0.9) !important;
        transform-origin: 0 0 !important;
        -webkit-transform-origin: 0 0 !important;
    }

    .signinCheckboxLabel {
        color: #ffffff !important;
        margin: 15px 0px 10px 0px !important;

    }

    .signinCheckboxLabel :global(.MuiButtonBase-root) {
        padding: 0px !important;
        margin-right: 5px !important;
    }

}

@media screen and (max-width: 325px) and (min-width: 0px) {
    .gRecaptcha {
        transform: scale(0.8) !important;
        transform-origin: 0 0 !important;
        -webkit-transform-origin: 0 0 !important;
    }
}