.iconItem {
    margin: 30px 0px 30px !important;
}

.dialogDesc {
    color: white !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}

.primaryButtonStyles {
    background-color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #1A2537 !important;
    border-radius: 5px !important;
    text-transform: none !important;
}

.secondaryButtonStyle {
    background-color: transparent !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    text-transform: none !important;
    border-radius: 5px !important;
    border: 1px solid #ffffff !important;
}

.buttonContainer {
    justify-content: center;
    gap: 20px !important;
    margin: 40px 0px 10px !important;
}

@media screen and (max-width: 503px) and (min-width: 0px) {
    .buttonContainer {
        justify-content: space-between;
        gap: 0px !important;
        margin: 40px 0px 10px !important;
    }

    .dialogDesc {
        color: white !important;
        font-size: 17px !important;
        font-weight: 400 !important;
    }
}

@media screen and (max-width: 416px) and (min-width: 0px) {
    .buttonContainer {
        row-gap: 20px !important;
        justify-content: center;
    }
}