.autoCompleteStyles {
    background-color: #ffffff !important;
    border-radius: 5px;
}

.autoCompleteStyles :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.autoCompleteStyles :global(.MuiInputBase-input) {
    padding: 3px 10px !important;
}

.chip {
    background-color: transparent !important;
    border: 1px solid #1A2537 !important;
    border-radius: 7px !important;
    margin: 0px 5px !important;
    color: #1A2537 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}