.sendLinkBtn {
    background-color: #273348 !important;
    box-shadow: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    text-transform: none !important;
    padding: 10px 10px !important;
}

.resetTabBGItem {
    background-color: #1A2537;
}

.resetMacLogo {
    width: 300px;
    height: auto;
}

.ellipsisMainItem {
    position: absolute;
    top: 0;
    right: 0;
}

.ellipsisMainItem img {
    width: 500px;
    height: auto;
}

.otpInputWrapperItem div {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.otpInputWrapper input {
    margin: 0px !important;
    background-color: #F7FAFC !important;
    border-bottom: 1px solid #000000 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    width: 50px !important;
    height: 50px;
    box-shadow: none !important;
    font-size: 20px !important;
    color: #000000 !important;
}




@media screen and (max-width: 1200px) and (min-width: 0px) {
    .ellipsisMainItem img {
        width: 500px;
        height: auto;
    }

    .resetFieldMainItem {
        margin: 30px 0px 0px !important;
    }

    .sendLinkBtn {
        background-color: #273348 !important;
        border: 1px solid rgb(88, 88, 88) !important;
    }

    .resetFormItem {
        margin: 50px auto !important;
        z-index: 1;
    }

    .otpInputWrapper input {
        margin: 0px !important;
        background-color: #48515f !important;
        border-bottom: 1px solid white !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        width: 50px !important;
        height: 50px;
        box-shadow: none !important;
        font-size: 20px !important;
        color: #ffffff !important;
    }

}

@media screen and (max-width: 600px) and (min-width: 0px) {
    .ellipsisMainItem img {
        width: 280px;
        height: auto;
    }

    .resetFieldMainItem {
        margin: 30px 0px 0px !important;
    }
}

@media screen and (max-width: 400px) and (min-width: 0px) {
    .otpInputWrapper input {
        margin: 0px !important;
        background-color: #48515f !important;
        border-bottom: 1px solid white !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        width: 40px !important;
        height: 40px;
        box-shadow: none !important;
        font-size: 16px !important;
        color: #ffffff !important;
    }
}

@media screen and (max-width: 350px) and (min-width: 0px) {
    .resetMacLogo {
        width: 90%;
        height: auto;
    }

    .timeRemainingItem {
        width: 100% !important;
        min-width: 100% !important;
        text-align: start !important;
        margin: 15px 0px !important;
    }

    .verificationItem {
        width: 100% !important;
    }

}