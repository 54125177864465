.navbarMainContainer {
    top: 0px !important;
    position: sticky !important;
    z-index: 10 !important;
    background-color: #1a2537 !important;
    padding: 20px 0px 0px !important;
}

.navbarMainContainerForBelow1200 {
    padding: 0px 0px 0px !important;
}

.navbarMainItem {}

.navbarHeadText {
    color: #ffffff !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.navChildContainer {
    justify-content: end;
}