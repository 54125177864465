.approveBtn {
    background-color: #489A3E !important;
    color: #FFFFFF !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border-radius: 5px !important;
    box-shadow: none;
}

.rejectBtn {
    background-color: #DA0202 !important;
    color: #FFFFFF !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border-radius: 5px !important;
    box-shadow: none;
}