.appCardMainItem {
    max-width: 30% !important;
    min-width: 370px !important;
    width: 30% !important;
    margin: 10px 0px !important;
}

.appInstallBtn {
    background-color: #0280FA !important;
    color: white !important;
    border-radius: 8px !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 5px 15px !important;
}

.appBtnText {
    color: #8EC200 !important;
    background-color: transparent !important;
    border-radius: 3px !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 5px 15px !important;
    cursor: auto !important;
    border: 1px solid #8EC200 !important;
    box-shadow: none !important;
}

.appBtnText .MuiTouchRipple-root {
    box-shadow: none !important;
}

.headTextItem {
    height: 57px !important;
}

.revenueLogoImages {
    width: 120px !important;
    max-width: 130px !important;
    height: 130px !important;
    max-height: 100px !important;
}

.revenueLogoContent {
    width: calc(100% - 130px) !important;
    max-width: calc(100% - 130px) !important;
}

@media screen and (max-width: 865px) and (min-width: 0px) {
    .appCardMainItem {
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        margin: 10px 0px !important;
    }


}

@media screen and (max-width: 600px) and (min-width: 0px) {
    .revenueLogoImages {
        width: 100px !important;
        max-width: 100px !important;
        height: 100px !important;
        max-height: 100px !important;
    }

    .revenueLogoContent {
        width: calc(100% - 115px) !important;
        max-width: calc(100% - 115px) !important;
    }
}

@media screen and (max-width: 600px) and (min-width: 0px) {
    .appInstallBtn {
        font-size: 13px !important;
        font-weight: 500 !important;
    }

    .appBtnText {
        font-size: 13px !important;
        font-weight: 500 !important;
    }
}