.dsGlobalFeatureCardStyle {
    background-color: #273348 !important;
    border-radius: 5px !important;
    padding: 15px !important;
}

.dsPorfolioCards {
    height: 315px !important;
    position: relative;
}

.dsPortfolioSectionContentItem {
    display: flex !important;
    align-items: center !important;
    height: calc(100% - 25px);
    display: flex;
    justify-content: center;
    // position: absolute;
    // top: 50%;
}

.dsPortfolioSectionContainer {
    height: 100% !important;
}

.featureCardMainWrapper {
    width: 180px !important;
    max-width: 180px !important;
    min-width: 180px !important;
}

.dsFeatureSectionItem {
    margin: 0px 0px 10px !important;
    height: auto !important;
}

.featureCardContainer {
    // overflow: auto;
    gap: 25px !important;
}

.mainContainer {
    height: 100vh;
    overflow-y: auto;
    flex-wrap: nowrap !important;
}

.dsCardHeadItem {
    margin: 0px 0px 20px !important;
}

.dsGlobalCardStyles {
    margin: 10px 0px !important;
}

.featureCardAppsItem {
    text-align: center !important;
    width: 150px !important;
    max-width: auto !important;
    // min-width: 150px !important;
    transition: transform 0.8s ease !important;
}

.featureCardAppsItem:hover {
    cursor: pointer;
    transform: scale(0.9);
}

.lifebrainItem {
    height: 335px !important;
    max-height: 335px !important;
    margin: 10px 0px !important;
}

.dashboardLeftSection {}

.dashboardRightSection {}

.keyIndicesValueitem {
    height: 480px !important;
    max-height: 480px !important;
    padding-right: 5px !important;
}

.tradingview-widget-copyright {
    margin: 10px 0px !important;
}

.dashboardSubnavMainContainer {
    position: sticky !important;
    top: 63px !important;
    align-items: center;
    gap: 10px !important;
    padding: 10px 0 !important;
    background-color: #1a2537 !important;
    z-index: 1;
    height: 60px;
    max-height: 60px;
    justify-content: space-between;
}

.dsMarketingContainer {
    margin: 0px 0px !important;
}

.pdfCarditem {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    max-width: 300px !important;
    width: 300px !important;
    min-height: 170px;
}

.pdfNameItem {
    margin: 10px 0px 0px !important;
}

.pdfMoreIcon {
    display: flex;
    justify-content: end;
    height: fit-content;
}

.pdfSubNameItem {
    margin: 5px 0px 0px !important;
}

.pdfNameItem span {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: black !important;
}

.pdfSubNameItem span {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #7E7E7E !important;
}


.dsGlobalFeatureCardStyle {
    margin: 10px 0px !important;
}

.customDatepicker {
    background-color: #f5f5f5;
    border-radius: 5px;
    color: #1976d2;
}

.customDatepicker input {
    padding: 10px 8px !important;
}

.customDatepicker .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.dashboardMarqueMainItem {
    margin: 0px 0px 5px;
}

.subNavHeadNameItem {
    width: calc(100% - 50px) !important;
}

// --------- REVENUE APP -----------
.startEndMonthItem {
    width: 200px !important;
}

.raSubmitBtn {
    background-color: #0280FA !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    padding: 8px 15px !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.revTableSmallWidth {
    width: 100px !important;
    min-width: 100px !important;
}

.revTableMediumWidth {
    width: 150px !important;
    min-width: 150px !important;
}

.revTableLargeWidth {
    width: 200px !important;
    min-width: 200px !important;
}

.revHeaderStyles {
    color: #1A2537 !important;
    background-color: #ffffff !important;
}

// --------------- OLD WORKING -----------
// .revTable {
//     border-collapse: separate !important;
//     border-spacing: 0 8px !important;
// }

// .revTable td {
//     border-bottom: none;
// }
// --------------- OLD WORKING -----------

.revTableContainer {
    height: calc(100vh - 325px) !important;
    max-height: calc(100vh - 325px) !important;
    overflow: auto;
}

.revTable {
    border-collapse: separate !important;
    border-spacing: 1px 1px !important;
}

.revTable thead {
    border-spacing: 0px 0px !important;
    transform: translate(0px, -5px) !important;
    position: sticky !important;
    top: 6px !important;
}

.revTable tbody td {
    border: none !important;
}

.revDataStyles {
    background-color: #394457 !important;
    color: #ffffff !important;
    padding: 10px 12px !important;
}

.revTableRow {
    border-radius: 15px !important;
}

.updateCredButton {
    background-color: #FFFFFF !important;
    color: #273348 !important;
    font-size: 12px !important;
    font-weight: 00 !important;
    text-transform: none !important;
    border-radius: 5px !important;
}



// --------- REVENUE APP -----------


// RESPONSIVE: 
@media screen and (max-width: 1440px) and (min-width: 0px) {

    .featureCardAppsItem {
        text-align: center !important;
        width: 130px !important;
        max-width: auto !important;
        // min-width: 150px !important;
        transition: transform 0.8s ease !important;
    }

    .lifebrainItem {
        width: 500px !important;
        margin: 10px auto !important;
    }

}

@media screen and (max-width: 1200px) and (min-width: 0px) {


    .dashboardSubnavMainContainer {
        position: sticky !important;
        top: 60px !important;
        padding: 8px 2px !important;
    }

    .pdfCarditem {
        padding: 10px !important;
        min-height: 80px;
    }

    .pdfImgItem {
        max-width: 40px !important;
        width: 40px !important;
        text-align: center;

    }

    .pdfContentItem {
        width: calc(100% - 50px) !important;
        max-width: calc(100% - 50px) !important;

    }

    .pdfImg {
        height: 35px;
        width: auto;
        vertical-align: middle;

    }

    .pdfNameItem {
        margin: 0px 0px 0px !important;
    }
}


@media screen and (max-width: 600px) and (min-width: 0px) {


    .lifebrainItem {
        height: 335px !important;
        max-height: 335px !important;
        margin: 10px 0px !important;
    }

    .featureCardAppsItem {
        min-width: 135px !important;
        width: 135px !important;
    }

    .featureCardContainer {
        // height: 250px !important;
        // flex-wrap: nowrap !important;
        // overflow-y: hidden !important;
        // overflow-x: auto !important;
    }

}