// ------ GLOBAL COLOR -------
$white: #ffffff;
$black: #000000;
$color-93FF00: #93FF00;
$color-D9D9D9: #D9D9D9;
$color-00B7FF: #00B7FF;
$color-171923: #171923;
$color-4B4B4B: #4B4B4B;
$color-F7FAFC: #F7FAFC;
$color-CFD9E0: #CFD9E0;
$color-141D2B: #141D2B;
$color-ACACAC: #ACACAC;
$color-C7C7C7: #C7C7C7;


.gblCWhite {
    color: $white !important;
}

.gblCBlack {
    color: $black !important;
}

.gblC93FF00 {
    color: $color-93FF00 !important;
}

.gblCD9D9D9 {
    color: $color-D9D9D9 !important;
}

.gbl141D2B {
    color: $color-141D2B !important;
}

.gblC00B7FF {
    color: $color-00B7FF !important;
}

.gblC171923 {
    color: $color-171923 !important;
}

.gblC4B4B4B {
    color: $color-4B4B4B !important;
}

.gblCF7FAFC {
    color: $color-F7FAFC !important;
}

.gblCCFD9E0 {
    color: $color-CFD9E0 !important;
}

.gblCACACAC {
    color: $color-ACACAC !important;
}

.gblC7C7C7 {
    color: $color-C7C7C7 !important;
}


// ------ GLOBAL COLOR -------



// ------ GLOBAL FONT -------
.globalHeadV1 {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #ffffff !important;
}

.globalHeadV2 {
    font-size: 22px !important;
    font-weight: 500 !important;
    color: #ffffff !important;
}

.globalTextV35 {
    font-size: 35px !important;
    font-weight: 600 !important;
}

.globalTextV28 {
    font-size: 28px !important;
    font-weight: 600 !important;
}

.globalTextV26 {
    font-size: 26px !important;
    font-weight: 600 !important;
}

.globalTextV24 {
    font-size: 24px !important;
    font-weight: 600 !important;
}

.globalTextV20 {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.globalTextV18 {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.globalTextV16 {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.globalTextV15 {
    font-size: 15px !important;
    font-weight: 600 !important;
}

.globalTextV14 {
    font-size: 14px !important;
    font-weight: 600 !important;
}

// ------ GLOBAL FONT -------


// ------ WRAPPER -------
.gblHeaderOutletContainer {
    position: sticky;
    top: 0;
    z-index: 10;
}

.gblCardBgWrapper {
    background-color: #273348 !important;
    border-radius: 5px !important;
    padding: 15px !important;
}

.gblWrapperOuterSpacing {
    margin: 20px 0px !important;
}

.fullHeight {
    height: 100% !important;
}

.sideNavAdminItem {
    padding: 0px 5px !important;
}

.profileArrowItem {
    text-align: end !important;
}

// ------ WRAPPER -------


// ------ ELIPSIS -------
.gblOnelineEllipsis {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
}

.gblTwolineEllipsis {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 2 !important;
    line-clamp: 2 !important;
}

// ------ ELIPSIS -------


.mainContainer {
    width: 100% !important;
    height: 100vh !important;
}

.fullHeightStyle {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
}

.fullHeightForAdminNav {
    height: calc(100% - 60px) !important;
    min-height: calc(100% - 60px) !important;
    max-height: calc(100% - 60px) !important;
}

.globalContentBgWrapper {
    background-color: #1a2537 !important;
    // padding: 0px 20px 20px 20px !important;
}

.globalOutletWrapper {
    padding: 0px 20px 20px 20px !important;
}

.globleButtonStyleV1 {
    font-size: 15px !important;
    font-weight: 600 !important;
    background-color: #ffffff !important;
    color: #1A2537 !important;
    text-transform: none !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    padding: 8px 20px !important;
}

// new resource btn 
.globleButtonStyleV2 {
    font-size: 15px !important;
    font-weight: 600 !important;
    background-color: #273348 !important;
    color: #ffffff !important;
    text-transform: none !important;
    border-radius: 6px !important;
    box-shadow: 0px 0px 2px 0px #FFFFFF !important;
    padding: 8px 20px !important;
}

.globalTabMainItem {
    margin: 0px 0px 20px !important;
}

.cdMainItem {
    // background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin: 0 auto !important;
}

.gblOutlineBtn {
    background-color: transparent !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: 1px solid white !important;
}

.gblContainedBtn {
    background-color: #ffffff !important;
    color: #1A2537 !important;
    border-radius: 5px !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.subNavContainer {
    justify-content: end;
    gap: 15px !important;
}


// -------------SIDE NAVIGATION-------------------
.sidenavigationHeights {
    height: calc(100% - 60px) !important;
    padding: 20px 10px !important;
}

.sidenavigationHeightsCloseDrawer {
    height: calc(100% - 80px) !important;
    padding: 20px 10px !important;
}

// -------------SIDE NAVIGATION------------------


// --------------------------------
.dashboardWrapper {
    height: calc(100vh - 100px) !important;
    max-height: calc(100vh - 100px) !important;
    overflow: auto !important;
    padding: 0px 5px 0px 0px;
}

.dashboardWrapperWithAppnavbar {
    height: calc(100vh - 160px) !important;
    max-height: calc(100vh - 160px) !important;
    overflow: auto !important;
    padding: 0px 5px 0px 0px;
}

// --------------------------------


// --------------GLOBAL APP NAVBAR------------------
.navbarProfileItem {
    background-color: white !important;
    border-radius: 5px;
    width: auto !important;
    padding: 5px 10px !important;
}

.sideNavbarProfileItem {
    background-color: white !important;
    border-radius: 5px;
    width: auto !important;
    padding: 8px 8px !important;
}

.navProfileContainr {
    gap: 5px !important;
    align-items: center;
    cursor: pointer;
}

.sideNavProfileContainer {
    cursor: pointer;
    align-items: center;
    column-gap: 10px !important;
    margin: 30px 0 20px 0;
}

.appNavMainItem {
    background-color: #181A1C !important;
    padding: 10px 20px !important;
}

.appBarNav {
    height: 60px;
    max-height: 60px;
}

.sideNavigationPositionStyle {
    top: 60px !important;
}

.profileItem {
    width: 30px;
    height: 30px;
}

// --------------GLOBAL APP NAVBAR------------------




// --------- 401K PLAN -----------
.gblTableHeadStyles {
    background-color: #ffffff !important;
}

.gblTableStyles {
    border-collapse: separate !important;
    border-spacing: 1px 1px !important;
    // border-spacing: 0 8px !important;
    height: 100% !important;
}

.gblTableStyles td {
    border-bottom: none;
}

.gblTableCellText {
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 8px 15px !important;
}

.gblTableHeadCell {
    // border-radius: 5px !important;
    padding: 10px 15px !important;
}

.gblTableBodyRow {
    background-color: #394457;
    // border-radius: 5px !important;
}

.gblLeftRadius {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.gblRightRadius {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.gblTableField :global(.MuiInputBase-input) {
    padding: 5px 10px !important;
    font-size: 14px !important;
}

.gblTableField {
    background-color: #ffffff !important;
    border-radius: 5px !important;
}

.gblTableField :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.gblSubHeaderRow {
    background-color: #2F3F5A !important;
}

.gblTableDatepicker {
    background-color: #ffffff !important;
}

.gblTableDatepickerCell input {
    padding: 5px 10px !important;
}

.gblTableDatepickerCell :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

// --------- 401K PLAN -----------


// ---------TEXTFIELD WRAPPER -----------
.gblTextfieldItem {
    width: 270px !important;
    min-width: 270px !important;
    max-width: 270px !important;
}

// .globalFieldStyles input {
//     background-color: #F7FAFC !important;
// }

.globalFieldStyles :global(.MuiInputBase-root) {
    background-color: #F7FAFC !important;
    padding-right: 0px !important;
}

.gblFieldSpcaing {
    margin: 0px 0px 5px !important;
}

// ---------TEXTFIELD WRAPPER -----------


// ---------TABLE SELECT WRAPPER -----------
.gblTableTextfield input {
    background-color: #FFFFFF !important;
}

// ---------TABLE SELECT WRAPPER -----------



// ---------ACCORDIAN WRAPPER -----------
.gblAccordian :global(.MuiAccordionSummary-content) {
    margin: 5px 0px !important;
    min-height: 45px !important;
    max-height: 45px !important;
    align-items: center;
}

.gblAccordianSummary {
    min-height: 45px !important;
    max-height: 45px !important;
}

// ---------ACCORDIAN WRAPPER -----------


// ---------DROPDOWN WRAPPER -----------
.gblDropdownStyles {
    background-color: #ffffff !important;
}

.gblDropdownStyles :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.gblTableFieldDropdownSize :global(.MuiSelect-select) {
    padding: 4px 10px !important;
}

// ---------DROPDOWN WRAPPER -----------


// ---------DATEPICKER WRAPPER -----------
.globalDatepicker :global(.MuiInputBase-root) {
    background-color: white !important;
}

.globalDatepicker :global(.MuiInputBase-root .MuiInputBase-input) {
    padding: 10px 10px !important;
}

// ---------DATEPICKER WRAPPER -----------


// ---------TABLE -----------

.gblTableExtraLargeWidth {
    min-width: 200px !important;
    width: 250px !important;
    min-width: 250px !important;
}

.gblTableSmallWidth {
    min-width: 100px !important;
    width: 100px !important;
    min-width: 100px !important;
}

.gblTableExtraSmallWidth {
    min-width: 50px !important;
    width: 50px !important;
    min-width: 50px !important;
}

.gblTableMediumWidth {
    min-width: 150px !important;
    width: 150px !important;
    min-width: 150px !important;
}

.gblTableLargeWidth {
    min-width: 150px !important;
    width: 200px !important;
    min-width: 200px !important;
}

.gblMainContainer {
    // height: 100% !important;
    // height: calc(90vh - 220px) !important;
}

// ---------TABLE -----------


.gblFieldFitStyles {
    padding: 0px !important;
}

.gblTableDropdownFitField {
    height: 100% !important;
    background-color: transparent !important;
}

.gblFieldFitStyles :global(.MuiFormControl-root) {
    height: 100% !important;
}


.gblTableDropdownFitField :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.gblTableDropdownFitField :global(.MuiSvgIcon-root) {
    color: #ffffff !important;
}



// RESPONSIVE: 
@media screen and (max-width: 600px) and (min-width: 0px) {

    .gblTextfieldItem {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }

    .cdMainItem {
        padding: 5px;
    }

    .globleButtonStyleV1 {
        font-size: 13px !important;
        font-weight: 600 !important;
        background-color: #ffffff !important;
        color: #1A2537 !important;
        text-transform: none !important;
        border-radius: 6px !important;
        box-shadow: none !important;
        padding: 8px 10px !important;
    }

    .globleButtonStyleV2 {
        font-size: 13px !important;
        font-weight: 600 !important;
    }

    .globalOutletWrapper {
        padding: 0px 10px 20px 10px !important;
    }

    .gblTextfieldItem {
        width: fit-content !important;
        min-width: max-content !important;
    }
}