@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

* {
  font-family: "Nunito Sans", serif !important;
  font-optical-sizing: auto !important;
}

body {
  font-family: "Nunito Sans", serif !important;
  font-optical-sizing: auto !important;
}