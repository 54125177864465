.clientItemMainItem {
    padding: 10px !important;
    border: 1px solid #ffffff !important;
    border-radius: 5px;
}

.clientItemMainItem:hover {
    border: 1px solid #24c99a !important;
    cursor: pointer;
    border-radius: 5px;
}

.clientItemText {
    color: #ffffff;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.unintallBtn {
    background-color: #8a8a8a !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border-radius: 5px !important;
}

.closeBtn {
    background-color: transparent;
    border: 1px solid #ffffff !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #ffffff !important;
    text-transform: none !important;
    border-radius: 5px !important;
}

.globalSsoBtnStyles {
    background-color: #FFFFFF !important;
    color: #273348 !important;
    font-size: 15px !important;
    font-weight: 00 !important;
    text-transform: none !important;
    border-radius: 5px !important;
}

.ssoTitleLabel {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #FFFFFF !important;
}

.iconItem {
    max-width: 50px !important;
    width: 50px !important;
}

.btnItem {
    width: 100px !important;
    max-width: 100px !important;
}

.titleItem {
    width: calc(100% - 180px) !important;
    max-width: calc(100% - 180px) !important;
}

.closeBtnStyle {
    background-color: #1A2537 !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    border-radius: 5px !important;
    border: 1px solid gray !important;
    text-transform: none !important;
}

.cmHeaderContainer {
    justify-content: end;
    gap: 15px !important;
    align-items: center;
}


@media screen and (max-width: 600px) and (min-width: 0px) {
    .cmHeaderContainer {
        justify-content: space-between;

    }
}