.memberSearchbar {
    background-color: #ffffff !important;
    border-radius: 5px !important;
}

.memberSearchbar :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.memberSearchbarItem {
    width: 350px !important;
    text-align: end;
}

.memberSearchbarContainer {
    justify-content: end;
}


// ------------ USER --------------
.memberHeadFieldContainer {
    gap: 15px !important;
    align-items: center;
}

.subscribeSelectStyles {
    background-color: #ffffff !important;
}

.subscribeSelectStyles :global(.MuiSelect-select) {
    padding: 5px 14px !important;
}

.appStatusCell {
    width: 180px;
}

.multilineTextfield :global(.MuiInputBase-root) {
    background-color: #ffffff !important;
}

.multilineTextfield :global(.MuiOutlinedInput-notchedOutline) {
    border: none !important;
}

.miniFieldItem {
    width: 100% !important;
}

// ------------ USER --------------
@media screen and (max-width: 900px) and (min-width: 0px) {

    .memberUserFields {
        width: 48% !important;
        max-width: 48% !important;
    }

    .memberProfileWrapperFields {
        width: 46% !important;
        max-width: 46% !important;
    }

    .memberFirstHeadContainer {
        gap: 15px !important;
        justify-content: space-between;
    }

    .memberHeadFieldContainer {
        gap: 5px !important;
    }

    .memberUserFieldConatiner {
        justify-content: space-between;
    }

    .memberSecondWrapperFields {
        width: 48% !important;
        max-width: 48% !important;
    }
}

// @media screen and (max-width: 600px) and (min-width: 0px) {
//         .memberProfileWrapperFields {
//                 width: 48% !important;
//                 max-width: 48% !important;
//             }
// }

@media screen and (max-width: 600px) and (min-width: 0px) {


    .memberSearchbarContainer {
        justify-content: start;
        margin-top: 15px;
    }

    .memberSecondWrapperFields {
        width: 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 470px) and (min-width: 0px) {
    .memberUserFields {
        width: 100% !important;
        max-width: 100% !important;
    }
}