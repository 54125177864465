.portfolioMainItem {
    margin: 20px 0px !important;
}

.portfolioFieldItem {
    margin: 0px 0px 10px !important;
}


.portfolioFieldlabelItem {
    margin: 0px 0px 5px !important;
}

.portfolioBtn {
    background-color: #0280FA !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    text-transform: none !important;
}

.portfolioCustomDatepicker {
    background-color: #f5f5f5;
    border-radius: 5px;
    color: #1976d2;
}

.portfolioCustomDatepicker input {
    padding: 5px 8px !important;
}

.portfolioCustomDatepicker .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.submitBtn {
    background-color: #89C13D !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border-radius: 5px;
    padding: 7px 25px !important;
}

.deleteBtn {
    background-color: #CD1F23 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    padding: 7px 25px !important;
}

.btnContainers {
    justify-content: center;
    gap: 20px !important;
    margin: 10px 0px 10px !important;
}

.btnItem {
    position: sticky !important;
    bottom: 0px !important;
    background-color: #273348 !important;
}