.tv-widget-watch-list__short-name {
    color: #ffffff !important;
}

.tv-widget-watch-list__head-embed {
    padding: 0px !important;
}

.tv-widget-watch-list__table {
    padding: 0px !important;
}