.newResourceDialog :global(.MuiPaper-root) {
    margin: 0px !important;
    width: calc(100% - 30px) !important;
}

.imageContainer {
    justify-content: space-between;
}

.btnItem {
    position: sticky !important;
}

.closeBtn {
    background-color: #1A2537 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    padding: 8px 15px !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}


.gblItemSpacing {
    margin: 10px 0px !important;
}


// --reponsive--
@media screen and (max-width: 600px) and (min-width: 0px) {
    .imageContainer {
        row-gap: 20px;
    }
}